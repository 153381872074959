<script lang="ts" setup>
import type { LayoutModelMenuField } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ menuLinks: Array<LayoutModelMenuField> }>();

const localePath = useLocalePath();

const store = await useStore();
const { navigate } = await useCatalog(store.value, null);

const links = computed(() => {
  const links = props.menuLinks.map(menuLink => getLinkForType(menuLink, localePath));

  for (const link of links) {
    link.click = async () => {
      await navigate(link);
    };

    if (!link.subLinks) {
      continue;
    }

    for (const subLinksColumn of link.subLinks) {
      for (const subLink of subLinksColumn) {
        if (!subLink.subLinks) {
          continue;
        }

        for (const subSubLink of subLink.subLinks.at(0)!) {
          subSubLink.click = async () => {
            await navigate(subSubLink);
          };
        }
      }
    }
  }

  return links;
});
</script>

<template>
  <nav class="ms-[calc(5px+6%)] w-full lg:ms-auto lg:w-auto">
    <ul class="flex w-full flex-wrap items-center justify-between lg:gap-x-14">
      <li v-for="link in links" :key="link?.id">
        <LinkCustom v-if="!link!.subLinks" class="block underline-offset-[3px] hover:underline" :link="link" />
        <HeaderLinkWithPanel v-if="link!.subLinks" :link="link" />
      </li>
      <li class="hidden md:list-item">
        <HeaderLocaleSwitcher />
      </li>
    </ul>
  </nav>
</template>
