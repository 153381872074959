<script lang="ts" setup>
import type { LinkItem } from "~~/models/links";

defineProps<{ link: LinkItem }>();

const route = useRoute();

function isActiveLink(link: LinkItem) {
  if (!link?.href) {
    return false;
  }

  return route.fullPath.toString().includes(link.href);
}
</script>

<template>
  <NuxtLink
    v-if="link.href && link.click"
    :to="link.href"
    v-bind="$attrs"
    class="hover:text-primary cursor-pointer outline-0"
    :class="{ 'font-bold': isActiveLink(link) }"
    @click.prevent="link.click as Function"
  >
    <i v-if="link.iconName" :class="`ph-${link.iconName}`" />
    {{ link.label }}
    <slot name="content-after" />
  </NuxtLink>

  <NuxtLink
    v-else-if="link.href"
    :to="link.href"
    :target="link.target"
    v-bind="$attrs"
    class="hover:text-primary outline-0"
    :class="{ 'font-bold': isActiveLink(link) }"
  >
    <i v-if="link.iconName" :class="`ph-${link.iconName}`" />
    {{ link.label }}
    <slot name="content-after" />
  </NuxtLink>

  <span v-else v-bind="$attrs">{{ link.label }}</span>
</template>

<style scoped>

</style>
