<script lang="ts" setup>
// title template: https://nuxtseo.com/docs/seo-utils/guides/fallback-title
useHead({
  titleTemplate: "%s",
  bodyAttrs: {
    class: "sf-hangcha",
  },
});

// i18n
const head = useLocaleHead({
  dir: true,
  lang: true,
  seo: true,
  key: "id",
});
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <Link rel="preconnect" href="https://www.datocms-assets.com" />

      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>

      <Link rel="icon" type="image/png" href="/hangcha/favicon/favicon-48x48.png" sizes="48x48" />
      <Link rel="icon" type="image/svg+xml" href="/hangcha/favicon/favicon.svg" />
      <Link rel="shortcut icon" href="/hangcha/favicon/favicon.ico" />
      <Link rel="apple-touch-icon" sizes="180x180" href="/hangcha/favicon/apple-touch-icon.png" />
      <Meta name="apple-mobile-web-app-title" content="Hangcha" />
      <Link rel="manifest" href="/hangcha/favicon/site.webmanifest" />

      <!-- Disallows manual or browser zoom (for font sizes less that 15px, iOS Safari automatically zooms in
           on a webpage when focussing an input field but does not "unzoom" the webpage after blur) -->
      <Meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
    </Head>
    <Body>
      <HeaderBase />

      <main>
        <slot />
      </main>

      <FooterBase />
      <DevBreakpointBadge />
    </Body>
  </Html>
</template>

<style></style>
