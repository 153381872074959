<script lang="ts" setup>
import type { DropdownItem } from "#ui/types";
import type { LocaleObject } from "@nuxtjs/i18n";

defineOptions({
  inheritAttrs: false,
});

defineProps<{ isMobile?: boolean }>();

const { locale: currentLocale, locales } = useI18n();
const route = useRoute();
const switchLocalePath = useSwitchLocalePath();

const isOpen = ref(false);
watch(() => route.path, () => isOpen.value = false); // close menu when path changes, eg. /nl/catalog -> /nl/contact
watch(() => route.query, () => isOpen.value = false); // close menu when query changes, eg. ?propertyFilters=[1] -> ?propertyFilters=[2]

const availableLocales = computed(() => [locales.value.filter(l => l.code !== currentLocale.value)
  .map((locale: LocaleObject) => {
    return {
      label: locale.code,
      to: `${switchLocalePath(locale.code)}`,
    } satisfies DropdownItem;
  })]);
</script>

<template>
  <UDropdown
    v-model:open="isOpen"
    class="hidden md:block"
    mode="click"
    :close-delay="200"
    :ui="{
      wrapper: 'static',
      container: '!top-[30px] !p-0',
      width: 'w-4xl',
    }"
    :popper="{ placement: 'bottom-end' }"
    :items="availableLocales"
  >
    <div class="gap-x-2xs flex items-center">
      <UIcon name="i-ph-globe" />
      <span class="uppercase underline-offset-[3px] hover:underline">{{ currentLocale }}</span>
      <UIcon name="i-ph-caret-down" :class="{ '-rotate-180': isOpen }" class="transition-transform duration-300" />
    </div>

    <template #item="{ item }">
      <span
        class="text-primary flex w-full justify-center truncate p-1.5 text-left text-[1rem] uppercase hover:text-blue-700"
      >
        {{ item.label }}
      </span>
    </template>
  </UDropdown>

  <div class="gap-md flex uppercase md:hidden">
    <NuxtLink
      v-for="locale in locales"
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      :class="{ 'font-bold': locale.code === currentLocale }"
      class="outline-0"
      role="button"
    >
      {{ locale.code }}
    </NuxtLink>
  </div>
</template>

<style scoped></style>
